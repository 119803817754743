import React from 'react'

export default function Pad(props) {
  return (
    window.matchMedia('(hover: hover) and (pointer: fine)').matches ?
      <button 
        className="Pad"
        onMouseDown={e => props.handleClick(props.note)}
        data-note={props.note}
        data-device={"non-touch"}
      >
        {props.children}
      </button>
      :
      <button 
        className="Pad"
        onTouchStart={e => props.handleClick(props.note)}
        onTouchMove={e => props.handleDrag(e)}
        data-note={props.note}
        data-device={"touch"}  
      >
        {props.children}
      </button>
  )
}
