import React from 'react'
import Stack from '../Primitives/Stack'
import Pad from './Pad'
import Tappable from 'react-tappable';

export default function Vana(props) {
  let isPlaying = props.isPlaying,
      setIsPlaying = props.setIsPlaying,
      handleClick = props.handleClick,
      handleDrag = props.handleDrag,
      notesArray = props.notesArray,
      octaveShift = props.octaveShift,
      setOctaveShift = props.setOctaveShift

  notesArray = props.notesArray.map(note => {
    // Shift up those note values. We'll use this array later to build the pad deal.
    note = note.split("")
    note[note.length - 1] = parseInt(note[note.length - 1], 10) + parseInt(octaveShift, 10)
    note = note.join("")

    return note
  })

  console.log(notesArray)

  return (
    <main>
      <Stack spacing="large">
        <Stack spacing="small">
          <Stack orientation="horizontal" spacing="small">
            <Pad handleClick={handleClick} handleDrag={handleDrag} note={notesArray[0]}>1</Pad>
            <Pad handleClick={handleClick} handleDrag={handleDrag} note={notesArray[1]}>2</Pad>
            <Pad handleClick={handleClick} handleDrag={handleDrag} note={notesArray[2]}>3</Pad>
          </Stack>
          <Stack orientation="horizontal" spacing="small">
            <Pad handleClick={handleClick} handleDrag={handleDrag} note={notesArray[3]}>4</Pad>
            <Pad handleClick={handleClick} handleDrag={handleDrag} note={notesArray[4]}>5</Pad>
            <Pad handleClick={handleClick} handleDrag={handleDrag} note={notesArray[5]}>6</Pad>
          </Stack>
          <Stack orientation="horizontal" spacing="small">
            <Pad handleClick={handleClick} handleDrag={handleDrag} note={notesArray[6]}>7</Pad>
            <Pad handleClick={handleClick} handleDrag={handleDrag} note={notesArray[7]}>8</Pad>
            <Pad handleClick={handleClick} handleDrag={handleDrag} note={notesArray[8]}>9</Pad>
          </Stack>
        </Stack>
        <Stack className="controls" orientation="horizontal" spacing="small" center="true">
          <div id="Speaker" onClick={() => setOctaveShift(octaveShift + 1)}>
          </div>
          <div id="Control" className={isPlaying ? "playing" : "stopped"} title={isPlaying ? "Atmosphere On" : "Atmosphere Off"}>
            <Tappable id="Symbol" onTap={(() => setIsPlaying(!isPlaying))} pressDelay={25} style={{cursor: "none",}}>
              <svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M47 24c0 12.703-10.297 23-23 23S1 36.703 1 24 11.297 1 24 1s23 10.297 23 23zm1 0c0 13.255-10.745 24-24 24S0 37.255 0 24 10.745 0 24 0s24 10.745 24 24zM28.724 37.28l5.107-25.91L23.7 22.287 13.266 11.524l3.112 20.123 3.019-3.252 5.053 13.112 2.328-7.313 1.946 3.086zM16.99 16.802l6.028 6.22-1.253 1.35-4.775-7.57zm6.725 6.939l-1.398 1.507 4.16 6.595 4.419-13.881-4.565 8.476-2.616-2.697zm-7.41-6.149l4.756 7.54-1.307 1.408-3.448-8.948zm5.308 8.414l-1.46 1.572 4.207 10.915 1.72-5.404-4.467-7.083zm-6.409-8.49l3.792 9.84-1.945 2.097-1.847-11.937zM28.23 34.62l-1.055-1.671 3.635-11.417-2.58 13.088zm-3.832-11.613l6.306-6.797-4.605 8.552-1.701-1.756z" fill="#8D697A"/>
              </svg>
            </Tappable>
          </div>
          {/* <Stack spacing="small" id="Pitch">
            <span onClick={() => {
              octaveShift === 0 ?
                setOctaveShift(1)
                :
                setOctaveShift(0)
            }}>twinkle</span>
          </Stack> */}
        </Stack>
      </Stack>
      </main>
  )
}
