import React, {useState, useEffect, useRef} from 'react';
import Player from 'react-player'
import Tappable from 'react-tappable';
import Cursor from 'custom-cursor'

import { 
  Sampler, 
  Reverb, 
  Distortion, 
  Compressor, 
  Phaser,
  Volume } from 'tone'
import A2 from "../src/Media/A2.mp3"
import A3 from "../src/Media/A3.mp3"
import Db3 from "../src/Media/Db3.mp3"
import Db4 from "../src/Media/Db4.mp3"
import Eb4 from "../src/Media/Eb4.mp3"
import E3 from "../src/Media/E3.mp3"
import E4 from "../src/Media/E4.mp3"
import Ab3 from "../src/Media/Ab3.mp3"
import Ab4 from "../src/Media/Ab4.mp3"

import './App.css';
import Vana from './Components/Vana';

function App() {
  const [isLoaded, setLoaded] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [octaveShift, setOctaveShift] = useState(0)

  const [showInfo, setShowInfo] = useState(false)
  const [touchesBuffer, setTouchesBuffer] = useState(false)

  const sampler = useRef(null);
  const reverb = useRef(null);
  const distortion = useRef(null);
  const compressor = useRef(null)
  const phaser = useRef(null)
  const volume = useRef(null)

  const notes = { A2, A3, Db3, Db4, Eb4, E3, E4, Ab3, Ab4 }
  let notesArray = ["Db3", "E3", "Ab3", "A3", "A2", "Db4", "Eb4", "E4", "Ab4"]

  // For the custom cursor
  useEffect(() => {
    new Cursor({}).mount()
    return () => {
    }
  }, [])

  // For all the Tone.js stuff
  useEffect(() => {
    console.log("loading..")
    sampler.current = new Sampler(
      notes,
      {
        onload: () => {
          console.log("loaded!")
          setLoaded(true);
        }
      }
    ).toDestination()

    volume.current = new Volume(+6).toDestination()
    compressor.current = new Compressor({
      attack: .5,
      release: 1,
      threshold: -25, 
      ratio: 3
    })
    distortion.current = new Distortion({
      distortion: .25,
      wet: .5
    })

    compressor.current.connect(volume.current)

    if (window.matchMedia('(hover: hover) and (pointer: fine)').matches) {

      reverb.current = new Reverb({
        decay: 10,
        wet: .1
      })/* .toDestination(); */
      phaser.current = new Phaser({
        frequency: 3,
        octaves: 2,
        baseFrequency: 1200,
        wet: .5
      })/* .toDestination() */

      sampler.current.connect(distortion.current)
      distortion.current.connect(reverb.current)
      distortion.current.connect(phaser.current)
      reverb.current.connect(compressor.current)
      phaser.current.connect(compressor.current)

    } else {

      /* reverb.current = new Reverb({
        decay: 2,
        wet: .75
      }).toDestination();*/
    
      sampler.current.connect(distortion.current)
      distortion.current.connect(compressor.current)
      /* distortion.current.connect(reverb.current) */
    }

  }, []);

  const handleKeyDown = event => {
    try {
      // Find the pad and make it active
      let pad = [...document.querySelectorAll('button.Pad')].filter(pad => pad.innerHTML.toLowerCase() === event.key)[0]
      pad.className = pad.className + " active"

      // After a moment, make it inactive
      setTimeout(() => {
        pad.className = pad.className.split(" ")[0]
      }, 150)
      
      // Find the speaker, make it active
      let speaker = document.querySelector('#Speaker')
      speaker.className = speaker.className + " active"

      // After a moment, make it inactive
      setTimeout(() => {
        let classes = speaker.className.split(" ")
        classes.pop();
        speaker.className = classes.join(" ")
      }, 150)

      // Trigger the sample set in this pad's "note" property
      sampler.current.triggerAttack(pad.getAttribute('data-note'));
    } catch (error) {
      /* console.error(error) */
    }
  }

  const handleKeyUp = event => {
    try {
      let pad = [...document.querySelectorAll('button.Pad')].filter(pad => pad.innerHTML.toLowerCase() === event.key)[0]

      pad.className = pad.className.split(" ")[0]
    } catch (error) {
      console.error(error)
    }
  }

  // For adding/removing keyhandlers
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    /* document.addEventListener('keyup', handleKeyUp) */
    
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      /* document.removeEventListener('keyup', handleKeyUp) */
    }
  }, [])

  const handleClick = (note) => {
    // Find the speaker, make it active
    let speaker = document.querySelector('#Speaker')
    speaker.className = speaker.className + " active"

    // After a moment, make it inactive
    setTimeout(() => {
      let classes = speaker.className.split(" ")
      classes.pop();
      speaker.className = classes.join(" ")
    }, 150)

    let pad = [...document.querySelectorAll('button.Pad')].filter(pad => pad.getAttribute('data-note') === note)[0]
      pad.className = pad.className + " active"

      // After a moment, make it inactive
      setTimeout(() => {
        pad.className = pad.className.split(" ")[0]
      }, 150)

    // Trigger the sample set in this pad's "note" property
    sampler.current.triggerAttack(note);
  }

  const handleDrag = event => {
    if (event.touches) {
      // First, turn the TouchList into an array we can map
      let touchList = [...event.touches]

      // If there's nothing in the touch buffer, fill it now
      if (touchesBuffer === false) { setTouchesBuffer(touchList) }

      touchList.map((touch, i) => {
        if (touchesBuffer.length > 0) {
          // Figure out which element we're currently touching and which we were previously touching
          let currentElement = document.elementFromPoint(touch.clientX, touch.clientY)
          let prevElement = null 

          try {
            prevElement = document.elementFromPoint(touchesBuffer[i].clientX, touchesBuffer[i].clientY)
          } catch (error) { console.error(error) }
          
          // If these elements differ and the current element is a Pad, play the Pad's note and write it to the touch buffer
          if (prevElement !== null && (currentElement === prevElement) === false) {
            if (currentElement.className.includes("Pad")) {
              handleClick(currentElement.getAttribute('data-note'))
              setTouchesBuffer(touchList)
            }
          }
        }
      })
    
    }
  }

  return (
    <div 
      id="wrapper"
      className={showInfo ? "active" : ""} 
    >
      <Vana
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        handleClick={handleClick}
        handleDrag={handleDrag}
        notesArray={notesArray}
        octaveShift={octaveShift}
        setOctaveShift={setOctaveShift}
      />
      <div id="info" >
        <Tappable onTap={e => setShowInfo(!showInfo)}>
          <h1 id="showInfo"><a href="https://thanksinp.art"><strong>vana</strong>.thanksinp.art</a></h1>
        </Tappable>
        <p><em>Vana is a tone toy in the key of A.</em> You can play by touch or using keyboard keys. Press the 
        <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M15.17 8c0 4.234-2.936 7.17-7.17 7.17S.83 12.234.83 8 3.766.83 8 .83 15.17 3.766 15.17 8zM16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-6.425 4.427l1.702-8.637L7.9 7.43 4.422 3.84l1.037 6.708 1.007-1.084 1.684 4.37.776-2.437.649 1.029zM6.17 6.49L7.439 7.77l-.184.354L6.17 6.491zm1.735 1.423l-.466.502 1.338 1.848 1.522-4.277-1.522 2.826-.872-.9zm-2.47-2.05L7.02 8.377l-.435.47-1.15-2.983zm1.77 2.805l-.351.365 1.296 3.184.543-1.188-1.489-2.361zm-2.137-2.83l1.264 3.28-.669.268-.595-3.548zM9.41 11.54c-.028-.248-.352-.557-.352-.557l1.212-3.806s-.833 4.612-.86 4.363zM8.236 7.77l1.998-2.367L8.7 8.253l-.463-.483z" fill="#8D697A"/></svg>
        symbol to turn the Atmosphere on and off.</p>
      </div>
      <Player 
        className="video" 
        url="https://www.youtube.com/watch?v=xNN7iTA57jM" 
        loop={true} 
        playing={isPlaying}
      />
    </div>
  )
}

export default App;
